import UserTopNav from "../../../components/UserTopNav.vue";
import moment from "moment";
import { GET_DATA_ALARM } from "../../../store/auth.module";
import JwtServices from "../../../services/jwt.services";

export default {
  components: { UserTopNav },
  data: () => ({
    user: null,
    date_picker_1: false,
    date_picker_2: false,
    date: {
      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      default: true,
      defaultTable: true,
      defaultText: "All Time",
    },
    source: [],
    selected_severity: "",
    list_technology: ["2G", "3G", "4G"],
    list_severirty: ["Critical", "Major", "Minor"],
    list_periode: ["30 Hari Terakhir", "7 Hari Terakhir"],
    loading_overlay: false,
    loading: false,
    dialog_confirmation: false,

    pagination: {
      page: 1,
      rowsPerPage: 10,
      totalItems: 0,
      rowsPerPageItems: [10, 50, 100, 250, 500],
    },

    headers: [
      {
        text: "Created Time",
        value: "created_at",
        sortable: false,
      },
      { text: "Site ID", value: "site_id", sortable: false },
      { text: "Site Name", value: "site_name", sortable: false },
      { text: "Technology", value: "technology", sortable: false },
      { text: "Device", value: "device", sortable: false },
      { text: "Category", value: "category", sortable: false },
      { text: "Severity", value: "severity", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Start At", value: "start_at", sortable: false },
      { text: "Close At", value: "close_at", sortable: false },
      { text: "Description", value: "description", sortable: false },
      // { text: "Actions", value: "action", sortable: false },
    ],
    data_alarm: [],
    selected_data_alarm: [],
  }),
  mounted() {
    this.user = JwtServices.getUser();
    const payload = {
      limit: 10,
      page: 1,
      startDate: "",
      endDate: "",
    };
    this.getAlarm(payload);
  },
  filters: {
    moment: function(date) {
      return moment(date).format("DD/MM/YYYY - HH:mm:ss");
    },
  },
  methods: {
    submit() {
      this.$store.dispatch(GET_DATA_ALARM, this.selected_data_alarm);

      this.dialog_confirmation = false;
      this.loading_overlay = true;

      this.$router.push({ name: "Form Update Status Alarm" });
    },
    inputDate(val) {
      if (val == "table") {
        this.date.defaultTable = false;
      } else {
        this.date.default = false;
      }
    },
    getAlarmByPage() {
      const payload = {
        limit: this.pagination.rowsPerPage,
        page: this.pagination.page,
        startDate: this.date.default ? "" : this.date.startDate,
        endDate: this.date.default ? "" : this.date.endDate,
      };
      this.getAlarm(payload);
    },
    getAlarmByRow() {
      const payload = {
        limit: this.pagination.rowsPerPage,
        page: 1,
        startDate: this.date.default ? "" : this.date.startDate,
        endDate: this.date.default ? "" : this.date.endDate,
      };
      this.getAlarm(payload);
    },
    getAlarm(payload) {
      this.loading_overlay = true;
      let data = {
        limit: payload.limit,
        page: payload.page,
        sort: "desc",
        start_date: payload.startDate,
        end_date: payload.endDate,
        search: "",
        status: "open",
        province_id: "[]",
        city_id: "[]",
        district_id: "[]",
        sub_district_id: "[]",
        cell_operator_id: this.user.network_operator.id,
        severity: this.selected_severity,
      };

      fetch(
        process.env.VUE_APP_API_URL + `api/v2/${this.user.language}/alarm`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ` + this.user.token,
          },
        }
      )
        .then((response) => response.json())
        .then((result) => {
          this.data_alarm = [];
          this.loading_overlay = false;
          // Check error
          if (result.error) {
            this.data_alarm = [];
            this.pagination.page = 1;
            this.pagination.totalItems = 1;
            return;
          }

          this.data_alarm = result.data.data;
          this.pagination.page = result.data.pagination.current_page;
          this.pagination.totalItems = result.data.pagination.total_page;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clearSeverity() {
      this.selected_severity = "";
    },
    applyFilter() {
      const payload = {
        startDate: this.date.default ? "" : this.date.startDate,
        endDate: this.date.default ? "" : this.date.endDate,
        limit: this.pagination.rowsPerPage,
        page: 1,
      };

      this.getAlarm(payload);
    },
    resetFilter() {
      this.selected_severity = "";
      this.date.default = true;
      const payload = {
        limit: 10,
        page: 1,
        startDate: "",
        endDate: "",
      };
      this.getAlarm(payload);
    },
  },

  computed: {
    dateDisplay: {
      //convert html standard datetime value into local time
      get() {
        return {
          startDate: moment(this.date.startDate)
            .locale("id-ID")
            .format("DD-MM-YYYY"),
          endDate: moment(this.date.endDate)
            .locale("id-ID")
            .format("DD-MM-YYYY"),
          threemonthago: moment()
            .subtract(2, "month")
            .format("YYYY-MM-DD"),
        };
      },
    },
  },
};
